import { Box, Button } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';

import { Currency } from '../types';
import FancyBearTraitsHoneyPurchaseFormContainer from './FancyBearTraitsHoneyPurchaseFormContainer';
import { HoneyCheckoutFormValues } from '../types/purchaseValues';
import { constants } from 'ethers';
import { formatEtherBalance } from '../utils/numberUtils';
import { getCurrencySymbol } from '../utils/currencyUtils';
import useApproveHoneyContractCall from '../hooks/bc/useApproveHoneyContractCall';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import useEtherAmountsPercentageUtil from '../hooks/bc/useEtherAmountsPercentageUtil';
import useFancyBearTraitsHoneyPuchaseContractCall from '../hooks/bc/useFancyBearTraitsHoneyPuchaseContractCall';
import useHoneyTraitsSaleAllowanceContractCall from '../hooks/bc/useHoneyTraitsSaleAllowanceContractCall';
import usePurchaseFormValuesSumUtil from '../hooks/usePurchaseFormValuesSumUtil';
import useStoreContext from '../hooks/useStoreContext';
import useStoreSelectedTraitsTotalPriceUtil from '../hooks/useStoreSelectedTraitsTotalPriceUtil';
import useTraitCollectionContext from '../hooks/useTraitCollectionContext';
import useTranslate from '../hooks/useTranslate';

type FancyBearTraitsHoneyCheckoutContainerProps = {
  onSuccess: () => void;
};

const FancyBearTraitsHoneyCheckoutContainer = ({
  onSuccess,
}: FancyBearTraitsHoneyCheckoutContainerProps) => {
  const translate = useTranslate();
  const [walletAddress] = useConnectedAccount();
  const { traitCollection } = useTraitCollectionContext();
  const { traitSalesByTraitId } = useStoreContext();
  const { changedTraitIds } = useDressingRoomContext();
  const [allowance, readAllowance] =
    useHoneyTraitsSaleAllowanceContractCall(walletAddress);
  const { dynamicNft } = useDynamicNftContext();

  const [selectedTokenId, setSelectedTokenId] = useState<number | undefined>(
    dynamicNft.tokenId
  );

  const [formValues, setFormValues] = useState<HoneyCheckoutFormValues>({});

  const totalPrice = useStoreSelectedTraitsTotalPriceUtil(changedTraitIds);

  const valuesAmountSum = usePurchaseFormValuesSumUtil(formValues);

  const totalPercentage = useEtherAmountsPercentageUtil(
    valuesAmountSum,
    totalPrice
  );

  const isCheckingAllowance = allowance === undefined;
  const isFree = totalPrice.eq('0');
  const isCorrectAmountSet = valuesAmountSum.eq(totalPrice);
  const areEnoughAmountApproved = allowance?.gte(totalPrice) || false;

  const traitTokenIds = useMemo(
    () =>
      changedTraitIds?.map(traitId => traitSalesByTraitId[traitId].tokenId) ||
      [],
    [changedTraitIds, traitSalesByTraitId]
  );

  const [purchase, isPurchasing] = useFancyBearTraitsHoneyPuchaseContractCall(
    traitTokenIds,
    formValues,
    onSuccess
  );

  const [approve, isApproving] = useApproveHoneyContractCall(
    traitCollection.saleContractAddress,
    constants.MaxUint256,
    readAllowance
  );

  const handleValuesChange = useCallback(
    (formValues: HoneyCheckoutFormValues) => {
      setFormValues(formValues);
    },
    []
  );

  const handleTokenIdChange = useCallback((tokenId?: number) => {
    setSelectedTokenId(tokenId);
  }, []);

  return (
    <>
      <FancyBearTraitsHoneyPurchaseFormContainer
        selectedTokenId={selectedTokenId}
        values={formValues}
        onFancyBearChange={handleTokenIdChange}
        onValuesChange={handleValuesChange}
      />

      <Box
        position="relative"
        mt={isFree ? '4' : '6'}
        borderRadius="md"
        overflow="hidden"
      >
        {isCheckingAllowance ? (
          <Button w="full" colorScheme="dark" isLoading />
        ) : (
          <>
            {!areEnoughAmountApproved ? (
              <Button
                w="full"
                onClick={() => approve()}
                isLoading={isApproving}
              >
                {translate('purchase:approve')}
              </Button>
            ) : (
              <>
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  bottom="0"
                  width={`${totalPercentage}%`}
                  bg="primary.500"
                  transition="all 0.15s"
                />

                <Button
                  w="full"
                  colorScheme="primary"
                  onClick={() => purchase()}
                  isDisabled={!isCorrectAmountSet || !areEnoughAmountApproved}
                  isLoading={isPurchasing}
                >
                  {translate(
                    isFree ? 'purchase:submit:free' : 'purchase:submit',
                    {
                      price: formatEtherBalance(totalPrice),
                      currency: getCurrencySymbol(Currency.Honey),
                    }
                  )}
                </Button>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default FancyBearTraitsHoneyCheckoutContainer;
