import { FunctionComponent, useMemo } from 'react';
import VestingContext, {
  VestingContextValue,
} from '../contexts/VestingContext';

import useConnectedAccount from '../hooks/useConnectedAccount';
import useVestingAmountRemainingByWalletInBearsContractCall from '../hooks/bc/useVestingAmountRemainingByWalletInBearsContractCall';
import useVestingAmountRemainingByWalletInJarsContractCall from '../hooks/bc/useVestingAmountRemainingByWalletInJarsContractCall';

const VestingProvider: FunctionComponent = ({ children }) => {
  const [walletAddress] = useConnectedAccount();

  const [
    vestingAmountInBearsRemaining,
    vestingAmountInBearsRemainingSum,
    readVestingAmountRemainingInBears,
  ] = useVestingAmountRemainingByWalletInBearsContractCall(walletAddress);

  const [
    vestingAmountInJarsRemaining,
    vestingAmountInJarsRemainingSum,
    readVestingAmountRemainingInJars,
  ] = useVestingAmountRemainingByWalletInJarsContractCall(walletAddress);

  const vestingAmountRemainingSum = useMemo(
    () =>
      vestingAmountInBearsRemainingSum &&
      vestingAmountInJarsRemainingSum &&
      vestingAmountInBearsRemainingSum.add(vestingAmountInJarsRemainingSum),
    [vestingAmountInBearsRemainingSum, vestingAmountInJarsRemainingSum]
  );

  const isLoading =
    vestingAmountInBearsRemaining === undefined ||
    vestingAmountInJarsRemaining === undefined;

  const contextValue = useMemo<VestingContextValue>(
    () => ({
      vestingAmountInBearsRemaining,
      vestingAmountInBearsRemainingSum,
      readVestingAmountRemainingInBears,
      vestingAmountInJarsRemaining,
      vestingAmountInJarsRemainingSum,
      readVestingAmountRemainingInJars,
      vestingAmountRemainingSum,
      isLoading,
    }),
    [
      vestingAmountInBearsRemaining,
      vestingAmountInBearsRemainingSum,
      readVestingAmountRemainingInBears,
      vestingAmountInJarsRemaining,
      vestingAmountInJarsRemainingSum,
      readVestingAmountRemainingInJars,
      vestingAmountRemainingSum,
      isLoading,
    ]
  );

  return (
    <VestingContext.Provider value={contextValue}>
      {children}
    </VestingContext.Provider>
  );
};

export default VestingProvider;
