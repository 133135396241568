import { Icon, SimpleGrid } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import useLastDynamicTokenIdUtil from '../hooks/useLastDynamicTokenIdUtil';
import useTranslate from '../hooks/useTranslate';
import DynamicNftIcon from './DynamicNftIcon';
import StoreIcon from './StoreIcon';
import Tab from './Tab';
import WardrobeIcon from './WardrobeIcon';

const MainMenu: React.FC = () => {
  const translate = useTranslate();

  const lastDynamicNftTokenId = useLastDynamicTokenIdUtil();

  const menuLinks = useMemo(
    () => [
      {
        to: '/nfts',
        key: 'mainMenu:nfts',
        icon: DynamicNftIcon,
      },
      {
        to: `/nft/${lastDynamicNftTokenId}/store`,
        key: 'mainMenu:store',
        icon: StoreIcon,
      },
      {
        to: `/nft/${lastDynamicNftTokenId}/wardrobe`,
        key: 'mainMenu:wardrobe',
        icon: WardrobeIcon,
      },
    ],
    [lastDynamicNftTokenId]
  );

  return (
    <SimpleGrid columns={3}>
      {menuLinks.map(({ to, key, icon }) => (
        <NavLink key={to} to={to}>
          {({ isActive }) => (
            <Tab
              w="full"
              leftIcon={
                <Icon
                  as={icon}
                  w={{
                    base: '5',
                    sm: '6',
                  }}
                  h="auto"
                />
              }
              size="lg"
              isActive={isActive}
            >
              {translate(key)}
            </Tab>
          )}
        </NavLink>
      ))}
    </SimpleGrid>
  );
};

export default MainMenu;
