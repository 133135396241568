import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import {
  HoneyCheckoutFormValueKey,
  HoneyCheckoutFormValues,
} from '../types/purchaseValues';
import { useCallback, useEffect, useMemo } from 'react';

import { BigNumber } from 'ethers';
import DynamicNftImage from '../components/DynamicNftImage';
import DynamicNftSelect from '../components/DynamicNftSelect';
import HoneyPurchaseSlider from '../components/HoneyPurchaseSlider';
import NftLockedHoney from '../types/NftLockedHoney';
import TinyHeading from '../components/TinyHeading';
import config from '../constants/baseConfig';
import deepcopy from 'deepcopy';
import { getDistributedPurchaseValues } from '../utils/purchaseUtils';
import { getRandomInt } from '../utils/numberUtils';
import { getShortenedAddress } from '../utils/walletUtils';
import { isEmpty } from '../utils/objectUtils';
import { plural } from 'pluralize';
import useAllOwnedDynamicNftsTokenIdsUtil from '../hooks/useAllOwnedDynamicNftsTokenIdsUtil';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useDynamicNftsWithHoneyInVestingOrHiveUtil from '../hooks/useDynamicNftsWithHoneyInVestingOrHiveUtil';
import useJarsWithHoneyInVestingOrHiveUtil from '../hooks/useJarsWithHoneyInVestingOrHiveUtil';
import useStoreSelectedTraitsTotalPriceUtil from '../hooks/useStoreSelectedTraitsTotalPriceUtil';
import useTranslate from '../hooks/useTranslate';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';

type V2TraitsHoneyPurchaseFormContainerProps = {
  selectedTokenId?: number;
  values?: HoneyCheckoutFormValues;
  onValuesChange: (values: HoneyCheckoutFormValues) => void;
  onDynamicNftChange: (tokenId?: number) => void;
};

const V2TraitsHoneyPurchaseFormContainer = ({
  selectedTokenId,
  values = {},
  onValuesChange,
  onDynamicNftChange,
}: V2TraitsHoneyPurchaseFormContainerProps) => {
  const translate = useTranslate();
  const [walletAddress] = useConnectedAccount();
  const { honeyBalance } = useWalletAssetsContext();
  const { changedTraitIds } = useDressingRoomContext();
  const { dynamicNftTokenIds, isFancyBearsCollection } =
    useDynamicNftCollectionContext();

  const ownedTokenIds = useAllOwnedDynamicNftsTokenIdsUtil();
  const totalPrice = useStoreSelectedTraitsTotalPriceUtil(changedTraitIds);

  const jarsWithHoney = useJarsWithHoneyInVestingOrHiveUtil();
  const dynamicNftsWithHoney = useDynamicNftsWithHoneyInVestingOrHiveUtil();

  const dynamicNftHoney: NftLockedHoney | undefined = useMemo(
    () =>
      dynamicNftsWithHoney.find(
        ([tokenId]) => tokenId === selectedTokenId
      )?.[1] || {
        vestedHoneyAmount: BigNumber.from('0'),
        honeyInHiveAmount: BigNumber.from('0'),
      },
    [selectedTokenId, dynamicNftsWithHoney]
  );

  const otherDynamicNftsWithHoneyInHive = useMemo(
    () =>
      dynamicNftsWithHoney.filter(
        ([tokenId, amounts]) =>
          tokenId !== selectedTokenId && amounts.honeyInHiveAmount?.gt(0)
      ),
    [selectedTokenId, dynamicNftsWithHoney]
  );

  const isFree = totalPrice.eq('0');

  const isSelectedDynamicNftHasHoneyInHive =
    !!dynamicNftHoney?.honeyInHiveAmount?.gt(0);
  const isSelectedDynamicNftHasVestedHoney =
    !!dynamicNftHoney?.vestedHoneyAmount?.gt(0);

  const hasHoneyInWallet = honeyBalance && honeyBalance.gt(0);
  const hasJarsWithHoney = jarsWithHoney.length > 0;
  const hasOtherDynamicNftsWithHoney =
    otherDynamicNftsWithHoneyInHive.length > 0;

  const handleDynamicNftToFeedChange = useCallback(
    (tokenId?: number) => {
      const newValues = values ? deepcopy(values) : {};

      delete newValues[`hive:dynamicNft_${selectedTokenId}`];
      newValues[`hive:dynamicNft_${tokenId}`] = {
        amount: BigNumber.from('0'),
        isLocked: false,
      };

      if (isFancyBearsCollection) {
        delete newValues[`vesting:fancyBear_${selectedTokenId}`];
        newValues[`vesting:fancyBear_${tokenId}`] = {
          amount: BigNumber.from('0'),
          isLocked: false,
        };
      }

      onDynamicNftChange(tokenId);
      onValuesChange(newValues);
    },
    [
      selectedTokenId,
      values,
      isFancyBearsCollection,
      onValuesChange,
      onDynamicNftChange,
    ]
  );

  const handleValueChange = useCallback(
    (valueKey: HoneyCheckoutFormValueKey, value: BigNumber) => {
      const distributedValues = getDistributedPurchaseValues(
        values,
        totalPrice,
        valueKey,
        value
      );

      onValuesChange(distributedValues);
    },
    [totalPrice, values, onValuesChange]
  );

  const handleValueLockToggle = useCallback(
    (valueKey: HoneyCheckoutFormValueKey, isLocked?: boolean) => {
      const newValues = {
        ...values,
        [valueKey]: {
          ...(values[valueKey] || {
            amount: BigNumber.from('0'),
          }),
          isLocked,
        },
      };

      onValuesChange(newValues);
    },
    [values, onValuesChange]
  );

  const handleDrawDynamicNftTokenId = useCallback(
    () =>
      onDynamicNftChange(
        dynamicNftTokenIds[getRandomInt(0, dynamicNftTokenIds.length)]
      ),
    [onDynamicNftChange, dynamicNftTokenIds]
  );

  useEffect(() => {
    if (isEmpty(values) && honeyBalance && jarsWithHoney && dynamicNftHoney) {
      const initialValues: HoneyCheckoutFormValues = {};

      if (selectedTokenId && isFancyBearsCollection) {
        initialValues[`vesting:fancyBear_${selectedTokenId}`] = {
          amount: dynamicNftHoney.vestedHoneyAmount || BigNumber.from('0'),
          isLocked: false,
        };
      }

      jarsWithHoney.forEach(([tokenId, amounts]) => {
        if (amounts.vestedHoneyAmount) {
          initialValues[`vesting:honeyJar_${tokenId}`] = {
            amount: amounts.vestedHoneyAmount,
            isLocked: false,
          };
        }
      });

      initialValues['wallet'] = {
        amount: honeyBalance || BigNumber.from('0'),
        isLocked: false,
      };

      dynamicNftsWithHoney.forEach(([tokenId, amounts]) => {
        if (amounts.honeyInHiveAmount) {
          initialValues[`hive:dynamicNft_${tokenId}`] = {
            amount: amounts.honeyInHiveAmount,
            isLocked: false,
          };
        }
      });

      jarsWithHoney.forEach(([tokenId, amounts]) => {
        if (amounts.honeyInHiveAmount) {
          initialValues[`hive:honeyJar_${tokenId}`] = {
            amount: amounts.honeyInHiveAmount,
            isLocked: false,
          };
        }
      });

      const distributedValues = getDistributedPurchaseValues(
        initialValues,
        totalPrice
      );

      onValuesChange(distributedValues);
    }
  }, [
    values,
    honeyBalance,
    jarsWithHoney,
    dynamicNftHoney,
    totalPrice,
    isFancyBearsCollection,
    selectedTokenId,
    dynamicNftsWithHoney,
    onValuesChange,
  ]);

  return !isFree ? (
    <>
      <Box>
        <TinyHeading mb="2">
          {translate('purchase:dynamicNft:title', {
            dynamicNftName: config.dynamicNft.dynamicNftName,
          })}
        </TinyHeading>

        <HStack
          spacing="3"
          bg="dark.800"
          px="3"
          pt="3"
          pb={
            isSelectedDynamicNftHasHoneyInHive ||
            isSelectedDynamicNftHasVestedHoney
              ? '0'
              : '3'
          }
          borderRadius="md"
          borderBottomRadius={
            isSelectedDynamicNftHasHoneyInHive ||
            isSelectedDynamicNftHasVestedHoney
              ? '0'
              : 'md'
          }
        >
          <DynamicNftImage
            tokenId={selectedTokenId}
            objectFit="cover"
            transition="all 0.15s"
            size="256"
            w="10"
            flex="none"
          />

          <Box w="full">
            <DynamicNftSelect
              tokenId={selectedTokenId}
              ownedTokenIds={ownedTokenIds}
              allTokenIds={dynamicNftTokenIds}
              isClearable
              onChange={handleDynamicNftToFeedChange}
            />
          </Box>

          <Button
            size="sm"
            colorScheme="dark"
            flex="none"
            onClick={handleDrawDynamicNftTokenId}
          >
            {translate('purchase:dynamicNft:draw')}
          </Button>
        </HStack>

        {isSelectedDynamicNftHasVestedHoney && (
          <HoneyPurchaseSlider
            valueKey={`vesting:fancyBear_${selectedTokenId}`}
            values={values}
            max={dynamicNftHoney!.vestedHoneyAmount!}
            label={`#${selectedTokenId}`}
            badge={translate('common:vesting')}
            borderTopRadius="0"
            borderBottomRadius={
              isSelectedDynamicNftHasHoneyInHive ? 'none' : 'md'
            }
            pb={isSelectedDynamicNftHasVestedHoney ? '0' : '1.5'}
            onChange={handleValueChange}
            onLockToggle={handleValueLockToggle}
          />
        )}

        {isSelectedDynamicNftHasHoneyInHive && (
          <HoneyPurchaseSlider
            valueKey={`hive:dynamicNft_${selectedTokenId}`}
            values={values}
            max={dynamicNftHoney!.honeyInHiveAmount!}
            label={`#${selectedTokenId}`}
            badge={translate('common:hive')}
            borderTopRadius="0"
            pt={isSelectedDynamicNftHasVestedHoney ? '0' : '1.5'}
            onChange={handleValueChange}
            onLockToggle={handleValueLockToggle}
          />
        )}
      </Box>

      {hasHoneyInWallet && (
        <>
          <TinyHeading my="2">{translate('purchase:wallet:title')}</TinyHeading>

          <HoneyPurchaseSlider
            valueKey="wallet"
            values={values}
            max={honeyBalance}
            label={getShortenedAddress(walletAddress!, 3)}
            onChange={handleValueChange}
            onLockToggle={handleValueLockToggle}
          />
        </>
      )}

      {hasOtherDynamicNftsWithHoney && (
        <>
          <TinyHeading my="2">
            {translate('purchase:dynamicNfts:title', {
              dynamicNftName: plural(config.dynamicNft.dynamicNftName),
            })}
          </TinyHeading>

          <VStack spacing="2px">
            {otherDynamicNftsWithHoneyInHive.map(([tokenId, honeyAmounts]) => (
              <Box key={tokenId} w="full">
                <HoneyPurchaseSlider
                  valueKey={`hive:dynamicNft_${tokenId}`}
                  values={values}
                  max={honeyAmounts.honeyInHiveAmount || BigNumber.from('0')}
                  label={`#${tokenId}`}
                  badge={translate('common:hive')}
                  onChange={handleValueChange}
                  onLockToggle={handleValueLockToggle}
                />
              </Box>
            ))}
          </VStack>
        </>
      )}

      {hasJarsWithHoney && (
        <>
          <TinyHeading my="2">
            {translate('purchase:honeyInJars:title')}
          </TinyHeading>

          <VStack spacing="2px">
            {jarsWithHoney.map(([tokenId, honeyAmounts]) => {
              const hasVestedHoney =
                honeyAmounts.vestedHoneyAmount &&
                !honeyAmounts.vestedHoneyAmount.isZero();
              const hasHoneyInHive =
                honeyAmounts.honeyInHiveAmount &&
                !honeyAmounts.honeyInHiveAmount.isZero();

              return (
                <Box key={tokenId} w="full">
                  {hasVestedHoney && (
                    <HoneyPurchaseSlider
                      valueKey={`vesting:honeyJar_${tokenId}`}
                      values={values}
                      max={
                        honeyAmounts.vestedHoneyAmount || BigNumber.from('0')
                      }
                      label={`#${tokenId}`}
                      badge={translate('common:vesting')}
                      pb={hasHoneyInHive ? '0' : '1.5'}
                      borderBottomRadius={hasHoneyInHive ? '0' : 'md'}
                      onChange={handleValueChange}
                      onLockToggle={handleValueLockToggle}
                    />
                  )}

                  {hasHoneyInHive && (
                    <HoneyPurchaseSlider
                      valueKey={`hive:honeyJar_${tokenId}`}
                      values={values}
                      max={
                        honeyAmounts.honeyInHiveAmount || BigNumber.from('0')
                      }
                      label={`#${tokenId}`}
                      badge={translate('common:hive')}
                      pt={hasVestedHoney ? '0' : '1.5'}
                      borderTopRadius={hasVestedHoney ? '0' : 'md'}
                      onChange={handleValueChange}
                      onLockToggle={handleValueLockToggle}
                    />
                  )}
                </Box>
              );
            })}
          </VStack>
        </>
      )}
    </>
  ) : null;
};

export default V2TraitsHoneyPurchaseFormContainer;
