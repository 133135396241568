import { useMemo } from 'react';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useIsTraitsApprovedForStakingContractCall = (
  collectionAddress: string,
  walletAddress?: string
): [boolean | undefined, () => void] => {
  const args = useMemo(
    () =>
      walletAddress && [
        walletAddress,
        config.contracts.traitSwapContract.address,
      ],
    [walletAddress]
  );

  const [{ data: isApproved }, readAllowance] = useContractRead(
    {
      addressOrName: collectionAddress,
      contractInterface: config.contracts.traitsContract.interface,
    },
    'isApprovedForAll',
    { args }
  );

  return [isApproved as unknown as boolean, readAllowance];
};

export default useIsTraitsApprovedForStakingContractCall;
