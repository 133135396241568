import Panel from '../components/Panel';
import ReactGA from 'react-ga4';
import SeparatedList from '../components/SeparatedList';
import StakeTraitsSuccessPopup from '../components/StakeTraitsSuccessPopup';
import StoreIcon from '../components/StoreIcon';
import TraitTile from '../components/TraitTile';
import UnstakePopup from '../components/UnstakePopup';
import useApplyTraitsUnswapsUtil from '../hooks/useApplyTraitsUnswapsUtil';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import useGetStakedTraitsContractCall from '../hooks/bc/useGetStakedTraitsContractCall';
import useTranslate from '../hooks/useTranslate';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';
import WardrobeIcon from '../components/WardrobeIcon';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Icon,
  SimpleGrid,
  useBoolean,
  usePrevious,
} from '@chakra-ui/react';

const StakedTraitsContainer = () => {
  const translate = useTranslate();
  const { dynamicNftsInWallet, stakedFancyBears } = useWalletAssetsContext();
  const { dynamicNft } = useDynamicNftContext();
  const lastTokenId = usePrevious(dynamicNft.tokenId);
  const { traitsById, isFancyBearsCollection } =
    useDynamicNftCollectionContext();
  const [stakedTraitIds, readStakedTraitIds] = useGetStakedTraitsContractCall(
    dynamicNft.collectionAddress,
    dynamicNft.tokenId
  );

  const [selectedTraitIds, setSelectedTraits] = useState<string[]>();
  const [isUnstakePopupOpen, setIsUnstakePopupOpen] = useBoolean();
  const [isUnstakeSuccessPopupOpen, setIsUnstakeSuccessPopupOpen] =
    useBoolean();
  const isMultiple = (stakedTraitIds?.length || 0) > 1;
  const isInWallet = dynamicNftsInWallet?.includes(dynamicNft.tokenId);
  const isStaked =
    isFancyBearsCollection && stakedFancyBears?.includes(dynamicNft.tokenId);

  const isOwner = isInWallet || isStaked;

  const newTraitIds = useApplyTraitsUnswapsUtil(
    dynamicNft.originalTraitIds,
    dynamicNft.traitIds,
    selectedTraitIds
  );

  const handleUnstake = useCallback(
    (traitId: string) => {
      setSelectedTraits([traitId]);
      setIsUnstakePopupOpen.on();
    },
    [setIsUnstakePopupOpen]
  );

  const handleUnstakeAll = useCallback(() => {
    setSelectedTraits(stakedTraitIds);
    setIsUnstakePopupOpen.on();
  }, [setIsUnstakePopupOpen, stakedTraitIds]);

  const handleSuccess = useCallback(() => {
    setIsUnstakePopupOpen.off();
    setIsUnstakeSuccessPopupOpen.on();

    ReactGA.event({
      category: 'Trait Swap',
      action: 'Unstake',
      label: selectedTraitIds?.join(', ') || undefined,
    });
  }, [selectedTraitIds, setIsUnstakePopupOpen, setIsUnstakeSuccessPopupOpen]);

  useEffect(() => {
    if (lastTokenId === undefined) {
      return;
    }
    if (dynamicNft.tokenId !== lastTokenId) {
      readStakedTraitIds();
    }
  }, [dynamicNft, lastTokenId, readStakedTraitIds]);

  const renderItem = useCallback(
    (traitId: string) => {
      const trait = traitsById[traitId];

      if (!trait) {
        return <></>;
      }

      return (
        <TraitTile
          key={traitId}
          traitId={traitId}
          tokenId={trait.tokenId}
          category={trait.category}
          name={trait.name}
        >
          {(isInWallet || isStaked) && (
            <Button
              size="sm"
              colorScheme="dark"
              onClick={() => handleUnstake(traitId)}
              mr="1"
            >
              {translate('common:unstake')}
            </Button>
          )}
        </TraitTile>
      );
    },
    [traitsById, handleUnstake, isInWallet, isStaked, translate]
  );

  return (
    <Box w="full">
      <Panel borderBottomRadius={isOwner ? 'none' : undefined} w="full">
        <SeparatedList title={translate('stakedTraits:title')} columns={1}>
          {stakedTraitIds?.map(renderItem)}
        </SeparatedList>
      </Panel>

      {isOwner && (
        <Panel pt="0" borderTopRadius="none">
          {isMultiple && (
            <>
              <Button w="full" mb="3" onClick={handleUnstakeAll}>
                {translate('stakedTraits:unstakeAll', {
                  count: stakedTraitIds?.length,
                })}
              </Button>
            </>
          )}

          <SimpleGrid spacing="3" columns={2}>
            <Link to={`/nft/${dynamicNft.tokenId}/store`}>
              <Button
                as="span"
                colorScheme="dark"
                leftIcon={<Icon as={StoreIcon} w="5" h="5" />}
                isFullWidth
              >
                {translate('stakedTraits:buy')}
              </Button>
            </Link>

            <Link to={`/nft/${dynamicNft.tokenId}/wardrobe`}>
              <Button
                as="span"
                colorScheme="dark"
                leftIcon={<Icon as={WardrobeIcon} w="5" h="5" />}
                isFullWidth
              >
                {translate('stakedTraits:swap')}
              </Button>
            </Link>
          </SimpleGrid>
        </Panel>
      )}

      {selectedTraitIds && isUnstakePopupOpen && (
        <UnstakePopup
          isOpen
          originalTraitIds={dynamicNft.originalTraitIds}
          currentTraitIds={dynamicNft.traitIds}
          newTraitIds={newTraitIds}
          traitIdsToUnstake={selectedTraitIds}
          dynamicNftTokenId={dynamicNft.tokenId}
          onClose={setIsUnstakePopupOpen.off}
          onSuccess={handleSuccess}
        />
      )}

      {selectedTraitIds && isUnstakeSuccessPopupOpen && (
        <StakeTraitsSuccessPopup
          isOpen
          title={translate('unstake:success:title')}
          description={translate('unstake:success:description')}
          dynamicNftTokenId={dynamicNft.tokenId}
          newTraitIds={newTraitIds}
        />
      )}
    </Box>
  );
};

export default StakedTraitsContainer;
