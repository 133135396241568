import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import {
  HoneyCheckoutFormValueKey,
  HoneyCheckoutFormValues,
} from '../types/purchaseValues';
import { useCallback, useEffect } from 'react';

import { BigNumber } from 'ethers';
import FancyBearImage from '../components/FancyBearImage';
import FancyBearSelect from '../components/FancyBearSelect';
import HoneyPurchaseSlider from '../components/HoneyPurchaseSlider';
import TinyHeading from '../components/TinyHeading';
import config from '../constants/baseConfig';
import deepcopy from 'deepcopy';
import { getDistributedPurchaseValues } from '../utils/purchaseUtils';
import { getRandomInt } from '../utils/numberUtils';
import { getShortenedAddress } from '../utils/walletUtils';
import { isEmpty } from '../utils/objectUtils';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftVestingHoneyUtil from '../hooks/useDynamicNftVestedHoneyUtil';
import useJarsWithVestedHoneyUtil from '../hooks/useJarsWithVestedHoneyUtil';
import useStoreSelectedTraitsTotalPriceUtil from '../hooks/useStoreSelectedTraitsTotalPriceUtil';
import useTranslate from '../hooks/useTranslate';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';

type FancyBearTraitsHoneyPurchaseFormContainerProps = {
  selectedTokenId?: number;
  values?: HoneyCheckoutFormValues;
  onValuesChange: (values: HoneyCheckoutFormValues) => void;
  onFancyBearChange: (tokenId?: number) => void;
};

const FancyBearTraitsHoneyPurchaseFormContainer = ({
  selectedTokenId,
  values = {},
  onValuesChange,
  onFancyBearChange,
}: FancyBearTraitsHoneyPurchaseFormContainerProps) => {
  const translate = useTranslate();
  const [walletAddress] = useConnectedAccount();
  const { changedTraitIds } = useDressingRoomContext();
  const { honeyBalance, fancyBearsInWallet } = useWalletAssetsContext();

  const fancyBearVestedHoney = useDynamicNftVestingHoneyUtil(selectedTokenId);
  const jarsWithVestedHoney = useJarsWithVestedHoneyUtil();

  const totalPrice = useStoreSelectedTraitsTotalPriceUtil(
    changedTraitIds || []
  );
  const isFree = totalPrice.eq('0');
  const isFancyBearHasVestedHoney =
    fancyBearVestedHoney && !fancyBearVestedHoney.isZero();

  const handleFancyBearTokenIdChange = useCallback(
    (tokenId?: number) => {
      const newValues = deepcopy(values);

      delete newValues[`vesting:fancyBear_${selectedTokenId}`];

      newValues[`vesting:fancyBear_${tokenId}`] = {
        amount: BigNumber.from('0'),
        isLocked: false,
      };

      onFancyBearChange(tokenId);
      onValuesChange(newValues);
    },
    [values, selectedTokenId, onValuesChange, onFancyBearChange]
  );

  const handleValueChange = useCallback(
    (valueKey: HoneyCheckoutFormValueKey, value: BigNumber) => {
      const distributedValues = getDistributedPurchaseValues(
        values,
        totalPrice,
        valueKey,
        value
      );

      onValuesChange(distributedValues);
    },
    [values, totalPrice, onValuesChange]
  );

  const handleValueLockToggle = useCallback(
    (valueKey: HoneyCheckoutFormValueKey, isLocked?: boolean) => {
      const newValues = {
        ...values,
        [valueKey]: {
          ...(values[valueKey] || {
            amount: BigNumber.from('0'),
          }),
          isLocked,
        },
      };

      onValuesChange(newValues);
    },
    [values, onValuesChange]
  );

  const handleDrawFancyBearTokenId = useCallback(() => {
    onFancyBearChange(getRandomInt(1, config.fancyBears.fancyBearsTotalSupply));
  }, [onFancyBearChange]);

  useEffect(() => {
    if (
      isEmpty(values) &&
      honeyBalance &&
      jarsWithVestedHoney &&
      fancyBearVestedHoney
    ) {
      const initialValues: HoneyCheckoutFormValues = {};

      if (fancyBearVestedHoney) {
        initialValues[`vesting:fancyBear_${selectedTokenId}`] = {
          amount: fancyBearVestedHoney,
          isLocked: false,
        };
      }

      jarsWithVestedHoney.forEach(([tokenId, amount]) => {
        initialValues[`vesting:honeyJar_${tokenId}`] = {
          amount,
          isLocked: false,
        };
      });

      initialValues['wallet'] = {
        amount: honeyBalance || BigNumber.from('0'),
        isLocked: false,
      };

      const distributedValues = getDistributedPurchaseValues(
        initialValues,
        totalPrice
      );

      onValuesChange(distributedValues);
    }
  }, [
    values,
    totalPrice,
    honeyBalance,
    jarsWithVestedHoney,
    fancyBearVestedHoney,
    selectedTokenId,
    onValuesChange,
  ]);

  return !isFree ? (
    <>
      <Box position="relative" zIndex="dropdown">
        <TinyHeading mb="2">
          {translate('purchase:dynamicNft:title', {
            dynamicNftName: config.dynamicNft.dynamicNftName,
          })}
        </TinyHeading>

        <HStack
          borderRadius="md"
          spacing="3"
          bg="dark.800"
          px="3"
          py="3"
          pb={isFancyBearHasVestedHoney ? '0' : '3'}
          borderBottomRadius={isFancyBearHasVestedHoney ? '0' : 'md'}
        >
          <FancyBearImage
            tokenId={selectedTokenId}
            objectFit="cover"
            transition="all 0.15s"
            size="256"
            w="10"
            flex="none"
          />

          <Box w="full">
            <FancyBearSelect
              tokenId={selectedTokenId}
              ownedTokenIds={fancyBearsInWallet}
              isClearable
              onChange={handleFancyBearTokenIdChange}
            />
          </Box>

          <Button
            size="sm"
            colorScheme="dark"
            flex="none"
            onClick={handleDrawFancyBearTokenId}
          >
            {translate('purchase:dynamicNft:draw')}
          </Button>
        </HStack>

        {isFancyBearHasVestedHoney && (
          <HoneyPurchaseSlider
            valueKey={`vesting:fancyBear_${selectedTokenId}`}
            values={values}
            max={fancyBearVestedHoney}
            label={`#${selectedTokenId}`}
            badge={translate('common:vesting')}
            borderTopRadius="0"
            onChange={handleValueChange}
            onLockToggle={handleValueLockToggle}
          />
        )}
      </Box>

      {honeyBalance && !honeyBalance.isZero() && (
        <>
          <TinyHeading my="2">{translate('purchase:wallet:title')}</TinyHeading>

          <HoneyPurchaseSlider
            valueKey="wallet"
            values={values}
            max={honeyBalance}
            label={getShortenedAddress(walletAddress!, 3)}
            onChange={handleValueChange}
            onLockToggle={handleValueLockToggle}
          />
        </>
      )}

      {jarsWithVestedHoney.length > 0 && (
        <>
          <TinyHeading my="2">
            {translate('purchase:honeyInJars:title')}
          </TinyHeading>

          <VStack spacing="2px">
            {jarsWithVestedHoney.map(([tokenId, honeyAmount]) => (
              <HoneyPurchaseSlider
                key={tokenId}
                valueKey={`vesting:honeyJar_${tokenId}`}
                values={values}
                max={honeyAmount}
                label={`#${tokenId}`}
                badge={translate('common:vesting')}
                onChange={handleValueChange}
                onLockToggle={handleValueLockToggle}
              />
            ))}
          </VStack>
        </>
      )}
    </>
  ) : null;
};

export default FancyBearTraitsHoneyPurchaseFormContainer;
