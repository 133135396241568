import { Button, HStack, ModalProps, VStack } from '@chakra-ui/react';
import { getCurrencyIcon, getCurrencySymbol } from '../utils/currencyUtils';

import CopyInput from './CopyInput';
import { Currency } from '../types';
import EtherAmount from './EtherAmount';
import FancyBearIcon from './FancyBearIcon';
import JarIcon from './JarIcon';
import SimplePopup from './SimplePopup';
import TinyHeading from './TinyHeading';
import WalletAsset from './WalletAsset';
import config from '../constants/baseConfig';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import { useNavigate } from 'react-router-dom';
import useTranslate from '../hooks/useTranslate';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';

type WalletPopupProps = Omit<ModalProps, 'children'>;

const WalletPopup = (props: WalletPopupProps) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { isFancyBearsCollection } = useDynamicNftCollectionContext();
  const [walletAddress, disconnect] = useConnectedAccount();
  const {
    ethBalance,
    honeyBalance,
    erc20Balance,
    honeyJarsInWallet,
    fancyBearsInWallet,
    stakedFancyBears,
  } = useWalletAssetsContext();

  const handleDisconnect = () => {
    disconnect();
    navigate('/');
  };

  return (
    <SimplePopup size="md" title={translate('walletPopup:title')} {...props}>
      <CopyInput value={walletAddress || ''} mt="6" mb="6" />

      <VStack spacing="2px" mb="6">
        <WalletAsset
          py="4"
          icon={getCurrencyIcon(Currency.Eth)}
          value={<EtherAmount amount={ethBalance} />}
        >
          <HStack pr="3" spacing="3">
            <TinyHeading>{getCurrencySymbol(Currency.Eth)}</TinyHeading>
            <Button
              as="a"
              href={config.urls.ethUniswapUrl}
              target="_blank"
              size="xs"
              variant="outline"
            >
              {translate('common:buy')}
            </Button>
          </HStack>
        </WalletAsset>

        <WalletAsset
          py="4"
          icon={getCurrencyIcon(Currency.Honey)}
          value={<EtherAmount amount={honeyBalance} />}
        >
          <HStack pr="3" spacing="3">
            <TinyHeading>{getCurrencySymbol(Currency.Honey)}</TinyHeading>
            <Button
              as="a"
              href={config.urls.honeyUniswapUrl}
              target="_blank"
              size="xs"
              variant="outline"
            >
              {translate('common:buy')}
            </Button>
          </HStack>
        </WalletAsset>

        {config.erc20.tokenName && (
          <WalletAsset
            py="4"
            icon={getCurrencyIcon(config.erc20.tokenName)}
            value={<EtherAmount amount={erc20Balance} />}
          >
            <HStack pr="3" spacing="3">
              <TinyHeading>{config.erc20.tokenName}</TinyHeading>
              <Button
                as="a"
                href={config.urls.erc20UniswapUrl}
                target="_blank"
                size="xs"
                variant="outline"
              >
                {translate('common:buy')}
              </Button>
            </HStack>
          </WalletAsset>
        )}

        {isFancyBearsCollection && (
          <WalletAsset
            py="4"
            icon={FancyBearIcon}
            iconSize="6"
            value={
              (fancyBearsInWallet?.length || '0') +
              (stakedFancyBears?.length ? ` + ${stakedFancyBears?.length}` : '')
            }
          >
            <HStack pr="3" spacing="3">
              <TinyHeading>{translate('common:fancyBears')}</TinyHeading>

              <Button
                as="a"
                href={config.urls.fancyBearsOpenSeaUrl}
                target="_blank"
                size="xs"
                variant="outline"
              >
                {translate('common:buy')}
              </Button>
            </HStack>
          </WalletAsset>
        )}

        <WalletAsset
          py="4"
          icon={JarIcon}
          iconSize="6"
          value={honeyJarsInWallet?.length || '0'}
        >
          <HStack pr="3" spacing="3">
            <TinyHeading>{translate('common:honeyJars')}</TinyHeading>
            <Button
              as="a"
              href={config.urls.honeyJarsOpenSeaUrl}
              target="_blank"
              size="xs"
              variant="outline"
            >
              {translate('common:buy')}
            </Button>
          </HStack>
        </WalletAsset>
      </VStack>

      <Button
        onClick={handleDisconnect}
        variant="solid"
        colorScheme="dark"
        w="full"
      >
        {translate('common:disconnect')}
      </Button>
    </SimplePopup>
  );
};

export default WalletPopup;
