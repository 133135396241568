import {
  getLevelAttribution,
  getV2TraitSaleHoneyPurchaseData,
} from '../../utils/purchaseUtils';
import { useContractWrite, useWaitForTransaction } from 'wagmi';
import { useEffect, useMemo } from 'react';

import { HoneyCheckoutFormValues } from '../../types/purchaseValues';
import config from '../../constants/baseConfig';
import useCaptureRpcException from '../useCaptureRpcException';
import useDynamicNftCollectionContext from '../useDynamicNftCollectionContext';
import useSnackbarRpcError from '../useSnackbarRpcError';
import useSnackbarRpcSuccess from '../useSnackbarRpcSuccess';
import useTraitCollectionContext from '../useTraitCollectionContext';

const useV2TraitsPurchaseWithHoneyContractCall = (
  selectedTokenId?: number,
  traitTokenIds?: number[],
  formValues?: HoneyCheckoutFormValues,
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const { traitCollection } = useTraitCollectionContext();
  const { dynamicNftCollection } = useDynamicNftCollectionContext();

  const args = useMemo(() => {
    if (!formValues || !traitTokenIds?.length) {
      return;
    }

    const purchaseData = getV2TraitSaleHoneyPurchaseData(
      traitTokenIds,
      formValues,
      dynamicNftCollection.contractAddress,
      config.contracts.honeyJarsContract.address
    );

    const levelAttribution = getLevelAttribution(
      selectedTokenId,
      dynamicNftCollection.contractAddress
    );

    return [purchaseData, levelAttribution];
  }, [
    selectedTokenId,
    traitTokenIds,
    formValues,
    dynamicNftCollection.contractAddress,
  ]);

  const [{ data, loading: isLoading, error: writeError }, purchase] =
    useContractWrite(
      {
        addressOrName: traitCollection.saleContractAddress,
        contractInterface: config.contracts.traitSaleContract.interface,
      },
      'purchaseTraitsWithHoney',
      { args }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({ confirmations: 1, wait: data?.wait });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useCaptureRpcException(waitError);
  useCaptureRpcException(writeError);

  console.log(
    traitCollection.saleContractAddress,
    'purchaseTraitsWithHoney',
    args
  );

  console.log('purchaseTraitsWithHoney', writeError);
  console.log('purchaseTraitsWithHoney', waitError);

  useEffect(() => {
    if (!isWaiting && waitData) {
      onSuccess?.();
    }
  }, [isWaiting, waitData]);

  return [purchase, isLoading || isWaiting];
};

export default useV2TraitsPurchaseWithHoneyContractCall;
