/* eslint-disable react-hooks/exhaustive-deps */
import { BigNumber, constants } from 'ethers';
import { useEffect } from 'react';
import { erc20ABI, useContractWrite, useWaitForTransaction } from 'wagmi';
import useCaptureRpcException from '../useCaptureRpcException';
import useSnackbarRpcError from '../useSnackbarRpcError';

const useApproveAnyErc20ContractCall = (
  tokenAddress: string,
  spenderAddress?: string,
  amount: BigNumber = constants.MaxUint256,
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const [{ data, loading: isLoading, error: writeError }, approve] =
    useContractWrite(
      {
        addressOrName: tokenAddress,
        contractInterface: erc20ABI,
      },
      'approve',
      {
        args: [spenderAddress, amount],
      }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({
      wait: data?.wait,
    });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);

  useCaptureRpcException(waitError);
  useCaptureRpcException(writeError);

  useEffect(() => {
    if (!isWaiting && waitData) {
      onSuccess?.();
    }
  }, [isWaiting, waitData]);

  return [approve, isLoading || isWaiting];
};

export default useApproveAnyErc20ContractCall;
