import { useEffect, useState } from 'react';

import { BigNumber } from 'ethers';
import config from '../../constants/baseConfig';
import useBigNumbersSumUtil from '../useBigNumbersSumUtil';
import { useContractRead } from 'wagmi';

const useVestingAmountRemainingByWalletInJarsContractCall = (
  walletAddress?: string
): [
  remaining: Record<number, BigNumber> | undefined,
  remainingSum: BigNumber | undefined,
  readVestingAmountRemaining: () => void
] => {
  const [remaining, setRemaining] = useState<Record<number, BigNumber>>();
  const remainingSum = useBigNumbersSumUtil(
    remaining ? Object.values(remaining) : []
  );

  const [{ data: remainingData }, readVestingAmountRemaining] = useContractRead(
    {
      addressOrName: config.contracts.honeyVestingContract.address,
      contractInterface: config.contracts.honeyVestingContract.interface,
    },
    'getVestingAmountRemainingInJarsByWallet',
    {
      args: walletAddress,
    }
  );

  useEffect(() => {
    if (remainingData !== undefined) {
      const data = remainingData as [BigNumber[], BigNumber[]];

      setRemaining(
        data[1].reduce(
          (prev, tokenId, i) => ({
            ...prev,
            [tokenId.toNumber()]: data[0][i],
          }),
          {}
        ) as Record<number, BigNumber>
      );
    }
  }, [remainingData]);

  return [remaining, remainingSum, readVestingAmountRemaining];
};

export default useVestingAmountRemainingByWalletInJarsContractCall;
