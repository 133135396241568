import { BigNumber, constants } from 'ethers';
import {
  FancyBearTraitSaleHoneyPurchaseData,
  V2TraitSaleHoneyPurchaseData,
} from '../types/purchaseData';
import {
  HoneyCheckoutFormValue,
  HoneyCheckoutFormValueKey,
  HoneyCheckoutFormValues,
} from '../types/purchaseValues';

import LevelAttribution from '../types/levelAttribution';

export const getLevelAttribution = (
  tokenId?: number,
  collectionAddress?: string
): LevelAttribution => ({
  collection:
    tokenId && collectionAddress ? collectionAddress : constants.AddressZero,
  tokenId: tokenId || 0,
});

const getUpdatedValue = (
  valueKey: HoneyCheckoutFormValueKey,
  newValue: HoneyCheckoutFormValue,
  originalValues: HoneyCheckoutFormValues
) => ({
  ...originalValues,
  [valueKey]: {
    ...(originalValues[valueKey] || {
      isLocked: false,
      amount: BigNumber.from('0'),
    }),
    ...newValue,
  },
});

export const getDistributedPurchaseValues = (
  currentValues: HoneyCheckoutFormValues,
  totalPrice: BigNumber,
  valueKey?: HoneyCheckoutFormValueKey,
  valueAmount?: BigNumber
) => {
  const currentAmount = valueAmount ? valueAmount : BigNumber.from('0');
  const valuesToUpdate = Object.entries(currentValues).filter(
    ([entryKey]) => entryKey !== valueKey
  ) as [HoneyCheckoutFormValueKey, HoneyCheckoutFormValue][];

  const otherValuesAmount = valuesToUpdate
    .reduce(
      (sum: BigNumber, [, { amount }]) => sum.add(amount),
      BigNumber.from('0')
    )
    .add(currentAmount);
  const unlockedValues = valuesToUpdate.filter(([, { isLocked }]) => !isLocked);

  let values: HoneyCheckoutFormValues = { ...currentValues };
  let amountToDistribute = totalPrice.sub(otherValuesAmount);

  if (amountToDistribute.lt(0)) {
    while (amountToDistribute.lt(0) && unlockedValues.length > 0) {
      const [entryKey, entryValue] = unlockedValues.pop()!;
      const bnAmount = BigNumber.from(entryValue.amount);
      const distributedAmount = bnAmount.gt(amountToDistribute.abs())
        ? amountToDistribute.abs()
        : bnAmount;

      amountToDistribute = amountToDistribute.add(distributedAmount);

      values = getUpdatedValue(
        entryKey,
        {
          amount: bnAmount.sub(distributedAmount),
        },
        values
      );
    }
  }

  if (valueKey) {
    values = getUpdatedValue(
      valueKey,
      {
        amount: amountToDistribute.lt(0)
          ? currentAmount.add(amountToDistribute)
          : currentAmount,
      },
      values
    );
  }

  return values;
};

export const getFancyBearTraitSaleHoneyPurchaseData: (
  traitTokenIds: number[],
  formValues: HoneyCheckoutFormValues
) => FancyBearTraitSaleHoneyPurchaseData | undefined = (
  traitTokenIds,
  formValues
) => {
  if (formValues) {
    const honeyJars = Object.keys(formValues)
      .filter(key => key.startsWith('vesting:honeyJar_'))
      .map(jarTokenId => ({
        tokenId: jarTokenId.replace('vesting:honeyJar_', ''),
        amount:
          formValues[jarTokenId as `vesting:honeyJar_${string}`]?.amount ||
          BigNumber.from('0'),
      }))
      .filter(({ amount }) => !BigNumber.from(amount).isZero());

    const fancyBears = Object.keys(formValues)
      .filter(key => key.startsWith('vesting:fancyBear_'))
      .map(bearTokenId => ({
        tokenId: bearTokenId.replace('vesting:fancyBear_', ''),
        amount:
          formValues[bearTokenId as `vesting:fancyBear_${string}`]?.amount ||
          BigNumber.from('0'),
      }));

    return {
      amountToSpendFromWallet: formValues.wallet?.amount || BigNumber.from('0'),
      traitTokenIds: traitTokenIds.map(tokenId => tokenId.toString()),
      amountPerTrait: traitTokenIds.map(() => '1'),
      honeyJars: honeyJars.map(({ tokenId }) => tokenId),
      amountToSpendFromHoneyJars: honeyJars.map(({ amount }) => amount),
      fancyBear: fancyBears.map(({ tokenId }) => tokenId),
      amountToSpendFromBear: fancyBears.map(({ amount }) => amount),
    };
  }
};

export const getV2TraitSaleHoneyPurchaseData: (
  traitTokenIds: number[],
  formValues: HoneyCheckoutFormValues,
  dynamicNftContractAddress: string,
  honeyJarsContractAddress: string
) => V2TraitSaleHoneyPurchaseData | undefined = (
  traitTokenIds,
  formValues,
  dynamicNftContractAddress,
  honeyJarsContractAddress
) => {
  if (formValues) {
    const dynamicNfts = Object.keys(formValues)
      .filter(key => key.startsWith('hive:dynamicNft_'))
      .map(tokenId => ({
        tokenId: tokenId.replace('hive:dynamicNft_', ''),
        amount:
          formValues[tokenId as `hive:dynamicNft_${string}`]?.amount ||
          BigNumber.from('0'),
      }))
      .filter(({ amount }) => !BigNumber.from(amount).isZero());

    const honeyJars = Object.keys(formValues)
      .filter(key => key.startsWith('hive:honeyJar_'))
      .map(tokenId => ({
        tokenId: tokenId.replace('hive:honeyJar_', ''),
        amount:
          formValues[tokenId as `hive:honeyJar_${string}`]?.amount ||
          BigNumber.from('0'),
      }))
      .filter(({ amount }) => !BigNumber.from(amount).isZero());

    return {
      ...getFancyBearTraitSaleHoneyPurchaseData(traitTokenIds, formValues)!,
      collectionsOnHive: [
        ...new Array(dynamicNfts.length || 0).fill(dynamicNftContractAddress),
        ...new Array(honeyJars.length || 0).fill(honeyJarsContractAddress),
      ],
      tokenIdsOnHive: [
        ...(dynamicNfts.map(({ tokenId }) => tokenId) || []),
        ...(honeyJars.map(({ tokenId }) => tokenId) || []),
      ],
      amountToSpendOnHive: [
        ...(dynamicNfts.map(({ amount }) => amount) || []),
        ...(honeyJars.map(({ amount }) => amount) || []),
      ],
    };
  }
};
