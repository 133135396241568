/* eslint-disable react-hooks/exhaustive-deps */

import { useContractWrite, useWaitForTransaction } from 'wagmi';
import { useEffect, useMemo } from 'react';

import { HoneyCheckoutFormValues } from '../../types/purchaseValues';
import config from '../../constants/baseConfig';
import { getFancyBearTraitSaleHoneyPurchaseData } from '../../utils/purchaseUtils';
import useCaptureRpcException from '../useCaptureRpcException';
import useSnackbarRpcError from '../useSnackbarRpcError';
import useSnackbarRpcSuccess from '../useSnackbarRpcSuccess';
import useTraitCollectionContext from '../useTraitCollectionContext';

const useFancyBearTraitsHoneyPuchaseContractCall = (
  traitTokenIds: number[],
  formValues: HoneyCheckoutFormValues,
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const { traitCollection } = useTraitCollectionContext();

  const args = useMemo(
    () => getFancyBearTraitSaleHoneyPurchaseData(traitTokenIds, formValues),
    [traitTokenIds, formValues]
  );

  const [{ data, loading: isLoading, error: writeError }, purchase] =
    useContractWrite(
      {
        addressOrName: traitCollection.saleContractAddress,
        contractInterface:
          config.contracts.fancyBearTraitSaleContract.interface,
      },
      'purchaseTraits',
      { args }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({ confirmations: 1, wait: data?.wait });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useCaptureRpcException(waitError);
  useCaptureRpcException(writeError);

  console.log(traitCollection.saleContractAddress, 'purchaseTraits', args);

  console.log('purchaseTraits', writeError);
  console.log('purchaseTraits', waitError);

  useEffect(() => {
    if (!isWaiting && waitData) {
      onSuccess?.();
    }
  }, [isWaiting, waitData]);

  return [purchase, isLoading || isWaiting];
};

export default useFancyBearTraitsHoneyPuchaseContractCall;
