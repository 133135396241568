enum FeatureFlag {
  TraitsSwapping = 'TRAITS_SWAPPING',
  DynamicNftSale = 'DYNAMIC_NFT_SALE',
  HiveHoneyPurchases = 'HIVE_HONEY_PURCHASES',
  VestedHoneyInJarsPurchases = 'VESTED_HONEY_IN_JARS_PURCHASES',
  VestedHoneyInBearsPurchases = 'VESTED_HONEY_IN_BEARS_PURCHASES',
  Erc20Purchases = 'ERC20_PURCHASES',
}

export default FeatureFlag;
