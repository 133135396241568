import { FunctionComponent, useCallback, useMemo } from 'react';
import WalletAssetsContext, {
  WalletAssetsContextValue,
} from '../contexts/WalletAssetsContext';

import BoldSpinner from '../components/BoldSpinner';
import { Center } from '@chakra-ui/react';
import { useBalance } from 'wagmi';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useDynamicNftsInWalletContractCall from '../hooks/bc/useDynamicNftsInWalletContractCall';
import useErc20BalanceContractCall from '../hooks/bc/useErc20BalanceContractCall';
import useFancyBearsInWalletContractCall from '../hooks/bc/useFancyBearsInWalletContractCall';
import useFetchStakedFancyBearsByWalletApiCall from '../hooks/api/useFetchStakedFancyBearsByWalletApiCall';
import useHoneyBalance from '../hooks/bc/useHoneyBalanceContractCall';
import useHoneyJarsInWalletContractCall from '../hooks/bc/useHoneyJarsInWalletContractCall';
import useStaticNftsInWalletContractCall from '../hooks/bc/useStaticNftsInWalletContractCall';

const WalletAssetsProvider: FunctionComponent = ({ children }) => {
  const [walletAddress] = useConnectedAccount();

  const [{ data: ethBalanceData }] = useBalance({
    addressOrName: walletAddress,
  });

  const [honeyBalance, readHoneyBalance] = useHoneyBalance(walletAddress);
  const [erc20Balance, readErc20Balance] =
    useErc20BalanceContractCall(walletAddress);

  const [staticNftsInWallet, readStaticNftsInWallet] =
    useStaticNftsInWalletContractCall(walletAddress);
  const [dynamicNftsInWallet, readDynamicNftsInWallet] =
    useDynamicNftsInWalletContractCall(walletAddress);

  const [fancyBearsInWallet, readFancyBearsInWallet] =
    useFancyBearsInWalletContractCall(walletAddress);

  const [honeyJarsInWallet, readHoneyJarsInWallet] =
    useHoneyJarsInWalletContractCall(walletAddress);

  const [stakedFancyBears, fetchStakedFancyBearsPromise] =
    useFetchStakedFancyBearsByWalletApiCall(walletAddress);

  const allOwnedFancyBears = useMemo(() => {
    if (fancyBearsInWallet !== undefined && stakedFancyBears !== undefined) {
      return [...fancyBearsInWallet, ...stakedFancyBears];
    }
    return undefined;
  }, [stakedFancyBears, fancyBearsInWallet]);

  const fetchStakedFancyBears = useCallback(() => {
    fetchStakedFancyBearsPromise(walletAddress!);
  }, [walletAddress, fetchStakedFancyBearsPromise]);

  const isLoading =
    ethBalanceData === undefined ||
    honeyBalance === undefined ||
    dynamicNftsInWallet === undefined ||
    fancyBearsInWallet === undefined ||
    honeyJarsInWallet === undefined ||
    stakedFancyBears === undefined;

  const contextValue = useMemo<WalletAssetsContextValue>(
    () => ({
      ethBalance: ethBalanceData?.value,
      erc20Balance,
      honeyBalance,
      staticNftsInWallet,
      dynamicNftsInWallet,
      fancyBearsInWallet,
      honeyJarsInWallet,
      stakedFancyBears,
      allOwnedFancyBears,
      readStaticNftsInWallet,
      readDynamicNftsInWallet,
      readFancyBearsInWallet,
      readHoneyJarsInWallet,
      readHoneyBalance,
      readErc20Balance,
      fetchStakedFancyBears,
      isLoading,
    }),
    [
      ethBalanceData,
      honeyBalance,
      erc20Balance,
      staticNftsInWallet,
      dynamicNftsInWallet,
      fancyBearsInWallet,
      honeyJarsInWallet,
      stakedFancyBears,
      allOwnedFancyBears,
      readStaticNftsInWallet,
      readDynamicNftsInWallet,
      readFancyBearsInWallet,
      readHoneyJarsInWallet,
      readHoneyBalance,
      readErc20Balance,
      fetchStakedFancyBears,
      isLoading,
    ]
  );

  return (
    <WalletAssetsContext.Provider value={contextValue}>
      {isLoading ? (
        <Center h="100vh">
          <BoldSpinner size="xl" />
        </Center>
      ) : (
        children
      )}
    </WalletAssetsContext.Provider>
  );
};

export default WalletAssetsProvider;
