import { useCallback, useEffect, useState } from 'react';
import useSnackbar from './useSnackbar';
import useTranslate from './useTranslate';

const useApiCall = <Result extends any, Data extends any = {} | void>(
  promise: (data: Data) => Promise<Result>,
  data?: Data
): [
  result: Result | undefined,
  fetch: (data: Data) => Promise<void>,
  isLoading: boolean
] => {
  const [result, setResult] = useState<Result>();
  const [isLoading, setIsLoading] = useState(true);
  const snackbar = useSnackbar();
  const translate = useTranslate();

  const fetchData = useCallback(
    async (data: Data) => {
      setIsLoading(true);
      try {
        const res = await promise(data);
        setResult(res);
      } catch (error: any) {
        snackbar('error', error?.message || translate('error:default'));
        console.error('sdf', error);
      } finally {
        setIsLoading(false);
      }
    },
    [promise, translate, snackbar]
  );

  useEffect(() => {
    if (data === undefined) {
      return;
    }

    fetchData(data);
  }, [data, promise, fetchData]);

  return [result, fetchData, isLoading];
};

export default useApiCall;
