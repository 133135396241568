import { SystemStyleFunction } from '@chakra-ui/theme-tools';
import { hexToRGB } from '../utils/colorUtils';

type StyleProps = Parameters<SystemStyleFunction>[0];

const baseTheme = {
  styles: {
    global: {
      body: {
        overflowX: 'hidden',
        backgroundColor: 'dark.800',
        fontWeight: '500',
        color: 'bright.50',
      },
      html: {
        height: 'auto',
        fontSize: {
          base: '14px',
          sm: '16px',
        },
      },
      '#root': {
        height: 'auto',
      },
      '*:focus': {
        boxShadow: 'none !important',
      },
      '*': {
        webkitTapHighlightColor: 'rgba(255, 255, 255, 0)',
      },
    },
  },
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
  },
  colors: {
    primary: {
      50: '#f2d980',
      100: '#efd166',
      200: '#edca4d',
      300: '#eac233',
      400: '#e8bb19',
      500: '#e5b300',
      600: '#cea100',
      700: '#b78f00',
      800: '#a07d00',
      900: '#896b00',
    },
    secondary: {
      50: '#f2d980',
      100: '#efd166',
      200: '#edca4d',
      300: '#eac233',
      400: '#e8bb19',
      500: '#e5b300',
      600: '#cea100',
      700: '#b78f00',
      800: '#a07d00',
      900: '#896b00',
    },
    dark: {
      50: '#c9c9c9',
      100: '#a8a8a8',
      200: '#888888',
      300: '#676767',
      400: '#474747',
      500: '#262626',
      600: '#202020',
      700: '#1b1b1b',
      800: '#151515',
      900: '#000000',
    },
    bright: {
      50: '#f8f8f8',
      100: '#efefef',
      200: '#e7e7e7',
      300: '#dedede',
      400: '#d6d6d6',
      500: '#cdcdcd',
      600: '#aaaaaa',
      700: '#878787',
      800: '#646464',
      900: '#424242',
    },
  },
  radii: {
    sm: '8px',
    md: '10px',
    lg: '12px',
  },
  components: {
    Container: {
      baseStyle: {
        maxW: 'container.sm',
        px: '4',
      },
    },
    Progress: {
      baseStyle: {
        track: {
          bg: hexToRGB('#FFFFFF', 0.25),
          borderRadius: 'full',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 'lg',
        textDecoration: 'none',
        boxSizing: 'bordered-box',
        borderWidth: '0',
      },
      variants: {
        solid: (props: StyleProps) => {
          if (props.colorScheme === 'dark') {
            return { color: 'bright.50' };
          }

          return { color: 'dark.900' };
        },
        outline: {
          border: '2px solid',
          color: 'bright.50',
          borderColor: 'dark.500',
          _active: {
            color: 'primary.500',
            bg: 'transparent',
            borderColor: 'primary.500',
          },
          _hover: {
            color: 'primary.500',
            bg: 'transparent',
            borderColor: 'primary.500',
          },
          _disabled: {
            color: 'dark.50',
            borderColor: 'dark.500',
            pointerEvents: 'none',
          },
        },
        ghost: {
          _hover: {
            bg: 'dark.800',
          },
          _active: {
            bg: 'dark.800',
          },
        },
        link: {
          color: 'bright.50',
          _active: {
            color: 'primary.500',
          },
          _hover: {
            color: 'primary.500',
            textDecoration: 'none',
          },
          _disabled: {
            color: 'bright.50',
            pointerEvents: 'none',
          },
        },
      },
      sizes: {
        xs: {
          px: 3,
          height: 8,
          minW: 8,
        },
        sm: {
          px: 4,
          height: 10,
          minW: 10,
        },
        md: {
          px: 8,
          height: 12,
          minW: 12,
        },
        lg: {
          h: 14,
          px: 6,
          minW: 12,
          fontSize: 'md',
        },
      },
      defaultProps: {
        colorScheme: 'primary',
      },
    },
    CloseButton: {
      baseStyle: {
        borderRadius: 'full',
        _hover: {
          bg: 'dark.600',
        },
      },
    },
    Badge: {
      baseStyle: {
        fontWeight: 'semibold',
        borderRadius: 'full',
      },
    },
    Input: {
      sizes: {
        sm: {
          field: {
            borderRadius: 'lg',
          },
        },
        md: {
          field: {
            borderRadius: 'lg',
          },
        },
        lg: {
          field: {
            px: 4,
            borderRadius: 'lg',
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: 'dark.500',
            border: '2px solid',
            _placeholder: {
              color: 'dark.100',
              opacity: 0.5,
              fontFamily: 'inherit',
            },
            _hover: {
              borderColor: 'dark.500',
            },
            _focus: {
              borderColor: 'primary.500',
              boxShadow: 'none',
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'primary.500',
        errorBorderColor: 'primary.500',
      },
    },
    InputRightAddon: {
      baseStyle: {
        bg: 'transparent',
      },
    },
    Link: {
      baseStyle: {
        color: 'dark',
        _hover: {
          color: 'primary.500',
          textDecoration: 'none',
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          boxShadow: 'none',
          bg: 'transparent',
          borderRadius: 'none',
        },
        body: {
          py: 6,
          px: {
            base: 4,
            sm: 6,
          },
          borderRadius: 'lg',
          bg: 'dark.900',
          color: 'bright.50',
        },
        closeButton: {
          top: 5,
          right: 5,
        },
        overlay: {
          bg: hexToRGB('#1b1b1b', 0.75),
        },
      },
      defaultProps: {
        isCentered: true,
      },
    },
    Divider: {
      baseStyle: {
        borderColor: 'dark.500',
        borderBottomWidth: '2px',
      },
    },
    Tooltip: {
      baseStyle: {
        bg: 'dark.500',
        color: 'bright.50',
        borderRadius: 'md',
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'sm',
        fontWeight: 'semibold',
        mb: '1',
        ml: '1.5',
      },
    },
  },
};

export default baseTheme;
