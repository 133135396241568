import { FunctionComponent, useMemo } from 'react';
import HiveContext, { HiveContextValue } from '../contexts/HiveContext';

import config from '../constants/baseConfig';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useHiveBalanceInTokensOfCollectionContractCall from '../hooks/bc/useHiveBalanceInTokensOfCollectionContractCall';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';

const HiveProvider: FunctionComponent = ({ children }) => {
  const { dynamicNftCollection } = useDynamicNftCollectionContext();
  const { honeyJarsInWallet } = useWalletAssetsContext();
  const { dynamicNftsInWallet } = useWalletAssetsContext();

  const [
    hiveBalanceInDynamicNfts,
    hiveBalanceInDynamicNftsSum,
    readHiveBalanceInDynamicNfts,
  ] = useHiveBalanceInTokensOfCollectionContractCall(
    dynamicNftCollection.contractAddress,
    dynamicNftsInWallet
  );

  const [
    hiveBalanceInHoneyJars,
    hiveBalanceInHoneyJarsSum,
    readHiveBalanceInHoneyJars,
  ] = useHiveBalanceInTokensOfCollectionContractCall(
    config.contracts.honeyJarsContract.address,
    honeyJarsInWallet
  );

  const hiveBalanceSum = useMemo(() => {
    if (hiveBalanceInDynamicNftsSum && hiveBalanceInHoneyJarsSum) {
      return hiveBalanceInDynamicNftsSum.add(hiveBalanceInHoneyJarsSum);
    }
    if (hiveBalanceInDynamicNftsSum) {
      return hiveBalanceInDynamicNftsSum;
    }
    if (hiveBalanceInHoneyJarsSum) {
      return hiveBalanceInHoneyJarsSum;
    }
  }, [hiveBalanceInDynamicNftsSum, hiveBalanceInHoneyJarsSum]);

  const isLoading =
    hiveBalanceInDynamicNfts === undefined ||
    hiveBalanceInHoneyJars === undefined;

  const contextValue = useMemo<HiveContextValue>(
    () => ({
      hiveBalanceInDynamicNfts,
      hiveBalanceInDynamicNftsSum,
      readHiveBalanceInDynamicNfts,
      hiveBalanceInHoneyJars,
      hiveBalanceInHoneyJarsSum,
      readHiveBalanceInHoneyJars,
      hiveBalanceSum,
      isLoading,
    }),
    [
      hiveBalanceInDynamicNfts,
      hiveBalanceInDynamicNftsSum,
      readHiveBalanceInDynamicNfts,
      hiveBalanceInHoneyJars,
      hiveBalanceInHoneyJarsSum,
      readHiveBalanceInHoneyJars,
      hiveBalanceSum,
      isLoading,
    ]
  );

  return (
    <HiveContext.Provider value={contextValue}>{children}</HiveContext.Provider>
  );
};

export default HiveProvider;
