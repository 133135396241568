import baseTheme from '../constants/baseTheme';
import baseTranslations from '../constants/baseTranslations';
import CacheProvider from './CacheProvider';
import config from '../constants/baseConfig';
import CurrencyProvider from '../providers/CurrencyProvider';
import deepmerge from 'deepmerge';
import DynamicNftCollectionProvider from './DynamicNftCollectionProvider';
import React, { useMemo } from 'react';
import TranslationsProvider from '../providers/TranslationsProvider';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { Provider as WagmiProvider } from 'wagmi';
import { providers } from 'ethers';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import {
  ChakraProvider,
  ChakraProviderProps,
  extendTheme,
} from '@chakra-ui/react';

const connectors = () => [
  new InjectedConnector({
    chains: config.chains,
    options: { shimDisconnect: true },
  }),
  new WalletConnectConnector({
    chains: config.chains,
    options: {
      infuraId: config.infura.infuraId,
      qrcode: true,
    },
  }),
  new CoinbaseWalletConnector({
    chains: config.chains,
    options: {
      appName: `Trait Swap | ${config.dynamicNft.dynamicNftCollection.name}`,
    },
  }),
];

const provider = ({ chainId }: { chainId?: number }) => {
  if (config.chains[0].name === 'Rinkeby') {
    return new providers.JsonRpcProvider(config.quicknode.rpcUrl);
  }

  const network = providers.InfuraProvider.getNetwork(chainId!);
  if (network.ensAddress) {
    return new providers.InfuraProvider(network, config.infura.infuraId);
  }

  return providers.getDefaultProvider();
};

type ComposedGlobalProvidersProps = {
  theme: ChakraProviderProps['theme'];
  translations: Record<string, string>;
};

const ComposedGlobalProviders: React.FC<ComposedGlobalProvidersProps> = ({
  translations,
  theme,
  children,
}) => {
  const mergedTheme = useMemo(
    () => extendTheme(theme ? deepmerge(baseTheme, theme) : baseTheme),
    [theme]
  );

  const mergedTranslations = useMemo(
    () =>
      translations
        ? deepmerge(baseTranslations, translations)
        : baseTranslations,
    [translations]
  );

  return (
    <ChakraProvider theme={mergedTheme}>
      <WagmiProvider autoConnect provider={provider} connectors={connectors}>
        <TranslationsProvider translations={mergedTranslations}>
          <DynamicNftCollectionProvider>
            <CurrencyProvider>
              <CacheProvider>{children}</CacheProvider>
            </CurrencyProvider>
          </DynamicNftCollectionProvider>
        </TranslationsProvider>
      </WagmiProvider>
    </ChakraProvider>
  );
};

export default ComposedGlobalProviders;
