import SelectableCard, { SelectableCardProps } from './SelectableCard';
import SupplyBadge from './SupplyBadge';
import TraitsPreview from './TraitsPreview';
import {
  AspectRatio,
  Box,
  BoxProps,
  Text
  } from '@chakra-ui/react';
import { ImageSize } from '../types';
import { useEffect, useState } from 'react';

export type TraitPreviewCardProps = {
  traitId: string;
  tokenId: number;
  traitIds: string[];
  totalSupply?: number;
  maxSupply?: number;
  imageSize?: ImageSize;
  canvasBg?: BoxProps['bg'];
  shapeOpacity?: number;
  isVisible?: boolean;
  onClick?: (traitId: string) => void;
} & Omit<SelectableCardProps, 'onClick'>;

const TraitPreviewCard: React.FC<TraitPreviewCardProps> = ({
  traitId,
  tokenId,
  traitIds,
  totalSupply,
  maxSupply,
  imageSize,
  canvasBg = 'dark.400',
  shapeOpacity,
  children,
  isVisible = true,
  isDisabled = false,
  onClick,
  ...rest
}) => {
  const [wasVisible, setWasVisible] = useState<boolean>();

  useEffect(() => {
    if (isVisible) {
      setWasVisible(true);
    }
  }, [isVisible]);

  return (
    <SelectableCard
      isDisabled={isDisabled}
      onClick={() => onClick?.(traitId)}
      opacity={isDisabled ? 0.6 : 1}
      {...rest}
    >
      <Box position="relative">
        <AspectRatio ratio={1} w="full">
          {wasVisible ? (
            <TraitsPreview
              traitIds={traitIds}
              imagesSize={imageSize}
              position="absolute"
              bgColor={canvasBg}
              zIndex={1}
            />
          ) : (
            <Box />
          )}
        </AspectRatio>

        {Number.isInteger(totalSupply) && Number.isInteger(maxSupply) && (
          <SupplyBadge
            position="absolute"
            size="sm"
            bottom="0"
            right="0"
            transform="translateY(50%)"
            totalSupply={totalSupply!}
            maxSupply={maxSupply!}
            zIndex={3}
            borderRightRadius="none"
          />
        )}
      </Box>

      <Box pt="1.5">
        {children || (
          <Text
            fontSize="sm"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontWeight="semibold"
          >
            #{tokenId}
          </Text>
        )}
      </Box>
    </SelectableCard>
  );
};

export default TraitPreviewCard;
