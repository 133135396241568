import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import config from '../constants/baseConfig';
import TraitCollectionContext, {
  TraitCollectionContextValue,
} from '../contexts/TraitCollectionContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import { TraitCollection } from '../types';

const TRAIT_COLLECTION_STORAGE_KEY = 'lastTraitCollection';

type TraitCollectionProviderProps = {};

const TraitCollectionProvider: FunctionComponent<
  TraitCollectionProviderProps
> = ({ children }) => {
  const { traitCollections } = useDynamicNftCollectionContext();

  const defaultTraitCollection = useMemo(() => {
    const lastTraitCollectionId = localStorage.getItem(
      TRAIT_COLLECTION_STORAGE_KEY
    );

    const lastCollection: TraitCollection | undefined = lastTraitCollectionId
      ? traitCollections.find(
          ({ traitCollectionId }) => traitCollectionId === lastTraitCollectionId
        )
      : undefined;

    return (
      lastCollection ||
      traitCollections.find(
        ({ traitCollectionId }) =>
          traitCollectionId === config.traits.defaultTraitCollectionId
      )!
    );
  }, [traitCollections]);

  const [traitCollection, setTraitCollection] = useState<TraitCollection>(
    defaultTraitCollection
  );

  const isFancyBearTraitCollection =
    traitCollection.contractAddress ===
    config.contracts.fancyBearTraitsContract.address;

  const changeTraitCollection = useCallback(
    (newTraitCollectionId: string) => {
      if (traitCollections) {
        const collection = traitCollections.find(
          ({ traitCollectionId }) => newTraitCollectionId === traitCollectionId
        );

        if (collection) {
          setTraitCollection(collection);
          localStorage.setItem(
            TRAIT_COLLECTION_STORAGE_KEY,
            collection.traitCollectionId
          );
        }
      }
    },
    [traitCollections]
  );

  const contextValue = useMemo<TraitCollectionContextValue>(
    () => ({
      traitCollection,
      changeTraitCollection,
      isFancyBearTraitCollection,
    }),
    [traitCollection, changeTraitCollection, isFancyBearTraitCollection]
  );

  return (
    <TraitCollectionContext.Provider value={contextValue}>
      {children}
    </TraitCollectionContext.Provider>
  );
};

export default TraitCollectionProvider;
